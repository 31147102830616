/*
body {
  padding-top: 5rem;
}
.starter-template {
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 100%;
}


body {
 height: 100%;
 position: relative;
}



#footer {
  background-color: #343a40;
  position: absolute;
  bottom: 0;
  width: 100%;
}
*/

amplify-authenticator {
  --container-height:auto;
}


img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

:root {
  --amplify-primary-color: #2196f3;
  --box-shadow:none !important;
}


amplify-authenticator {
  --box-shadow: none;
  --border-radius: 0;
  /* --padding: 0; */
  --margin-bottom: 20px;
}

div#amplify-signin amplify-sign-in#SignInButton {

  color:green;
  border:none;
  font-size: 15px;
}

html {
	-webkit-text-size-adjust: none;
  touch-action: manipulation;
  --box-shadow:none !important; 
  -webkit-tap-highlight-color:transparent;
}

.MuiAppBar-colorPrimary {
  /* background: linear-gradient(0deg, #2196f3 0%, #2196f3 75%, #51aefa 100%) !important; */
  background-color:white;
  background-image: url(/images/bg.svg);
  background-repeat:no-repeat;
  background-size: 540px auto;
}


#sumerian-scene-dom-id {
  height:100%;  
}


body {
  font-family:"Roboto", sans-serif;
}

#root { 
  /* height:100%; */
  display:inline-block;
  width:100%;
  min-height: 100vh; 
  display: flex; 
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2368bef4' fill-opacity='0.48' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"); 
}

.footer {
  flex-shrink:0;
  /* min-height: 24em; */
  padding-bottom: 2em;
  padding-top:2em;
  background-color: black;
}

.footer-disclaimer {
  text-align:center;
  padding-top:1em;
  margin-left:1em;
  margin-right:1em;
  font-size: 0.8em;
  color:#ccc;
}

.navbar {
  border-radius:0 !important;
  position: sticky;
  top: 0;
  margin-bottom: 0;
  z-index: 10 !important;
  min-height: 65px;
}

.nav-item {
  font-size: 1.3em;
  padding:0.5em;
  cursor: pointer;
}

.navbar-brand .nav-item {
  font-size: 1.3em;
  padding:0em;
}

.navbar-toggle {
  margin-top:18px;
}

/* .MuiToolbar-regular { */

  /* height:7em !important; */
/* } */


.container-nav-closed {
  /* transition: all 0.7s ease-in; */

  margin-bottom: 0em;

  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  
}

.container-nav-open {
  /* transition: all 0.7s ease-in; */


    /* transition-delay: 0.2s; */
    margin-bottom: 26em;

    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;

}

.contact-input {
  max-width: 18em;
}

.MuiToolbar-gutters {
  height:101px;
  flex-shrink: 0;
}

.sticky-nav {
  /* position: fixed !important; */
  /* top: 0; */
  z-index: 100;  
  height:50em;
  max-height: 7em;
  overflow: hidden;
  /* transition: max-height 0.25s ease-in; */
  /* animation:shrinkNav 1.0s forwards; 
  animation-duration: 2s;
  animation-play-state: running;  */
}

.sticky-nav.is-nav-open {
  /* height: 33em !important; */
  /* height:auto; */
  /* animation:growNav 2s linear 0s; */
  /* animation-duration: 1s; */
  /* animation-play-state: running;  */
  /* transition:max-height 1s; */
  /* transition-delay: 0.2s; */

  max-height:33em;
  overflow: hidden;

  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;


}

.sticky-nav.is-nav-closed {
  /* transition: max-height 1s; */
  /* transition-delay: 0.2s; */


  max-height:7em;
  overflow: hidden;

  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;

}


.sticky-nav-tall {
  /* position: fixed !important; */
  /* top: 0; */
  /* z-index: 100; */
  /* height:33em !important;
   */
  /* animation:growNav 1.0s forwards; 
  animation-duration: 2s;
  animation-play-state: running;  */

  height:33em;
  transition: height 0.25s;

}

@keyframes shrinkNav {
  100% {height:12em; }
}

@keyframes growNav {
  100% {max-height:33em; }
}


header {
position: sticky;
position: -webkit-sticky;
top: 0;
}

.navbar-brand img {
  height: 2.5em;
}

.navbar-brand {
  padding: .4em;
}

.navbar-inverse .navbar-nav>li>a {
    color:white;
    font-size: 14px;
}

.notification {
  display: block;
  position: absolute;
  top:6.9em;
  background-color: white;
  height:18em;  
  z-index: 100;
  width:100%;
  border:1px solid #ccc;
  /* opacity: 0; */
  margin-left:auto;
  margin-right:auto;

  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.notification-main {
  /* width:90%; */
  padding-left: 12px;
  padding-right: 12px;
  max-width:80em;
  margin-left:auto;
  margin-right: auto;
  text-align:center;
  top: 0em;
  font-family: 'Nunito','Roboto', Arial, Helvetica, sans-serif;
  font-weight: 1.4em;
}

.oops-container {
  min-width: 30em;
  padding-top: 3em;
  font-size: 2em;
}

.oops-container p {
  font-family: 'Nunito','Roboto', Arial, Helvetica, sans-serif;
}

.font-bold {

  font-weight: bold;
}

.large-font {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.medium-font {
  font-size: 1.3em;
  margin-bottom: 1em;
}

ul {
  padding-left: 0em !important;
}

.alert-dropdown {
  min-height: 3em;
  padding-top:1em;
  padding-left:1em;
  padding-right:1em;
  font-size: 1.2em;
  font-weight: medium;
  text-align: center; 
  position: fixed; 
  top:5.5em;
  width:100%;
  z-index: 100;
}

.notification.hide {
  display: none;
  opacity: 0;
  /* top: 6.9em; */
  /* animation: hideOpacity 0.8s forwards; */
  /* animation-play-state: paused;  */
  /* z-index: 100; */
}


.notification.show {
  display: normal;
  position: fixed;
  top:7em;
  /* display: none; */
  /* opacity: 0; */ 
  /* top: -50em;  */
  /* animation: showOpacity 0.8s forwards; */
  /* animation-play-state: paused;  */
  /* z-index: 100; */
}

@keyframes hide {
  100% {top: -50em; }
}

@keyframes show {
  100% {top: 6.9em; }
}

@keyframes hideOpacity {
  100% {opacity: 0; }
}

@keyframes showOpacity {
  100% {opacity: 100; }
}


.notification p {
  color:black;
}


.dropdown-button {
  margin:1em !important;
}

.dropdown {
  color:black;
}

.notification-group {
  padding:2em;  
  padding-top:1em;
  text-align: left;  
}

.notification-p {
  padding-left:1em;
}

.notification-main-left {  
  width:60%;
  display: inline-block;
  border-right: 1px solid #ccc;
}

.notification-main-right{
  width:40%;
  float:right;
}

.notification-main-right>div>button {
  margin-right: 0px;
}


.notification-left {  
  width:30%;
  display: inline-block;
}

.notification-left img {  
  width:100%;
  height:auto;
  display: inline-block;
}


.notification-right{
  width:68%;
  float:right;
}


.notification-right .product-title{
  padding-top: 0px;
}
.container-side-cart {
  max-width:400px;
  /* min-width:30em; */
}


.container-page {
  width:90%;  
  max-width: 1170px;
  margin-left: auto;
  margin-right:auto;
  padding-top:2em;
  background-color: rgb(255, 255, 255);
  padding:2em;
  border-radius: 0.5em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  min-height: 25em;
  background-color: #ffffff;
}


.container-group {
  padding:1em;
}
.container-page p {
  font-family:'Nunito', 'Roboto', sans-serif;
  font-size:1.4 em;
}

.container-page h3 {
  text-align: left;
  font-size: 1.6em;
  font-family:'Nunito', 'Roboto', sans-serif;

}

.container-page p {
  text-align: left;
}

.container-inner-admin {

  text-align: center;  

  margin-left:auto;
  margin-right:auto;
  padding-left:0em;
  padding-right:0em;
  padding-top:0em;
  width: 100%;

}

.container-inner {
/*   padding-top: 60px; */
  text-align: center;  
  max-width: 1400px;
  margin-left:auto;
  margin-right:auto;
  padding-left:0em;
  padding-right:0em;
  padding-top:0em;
  width: 100%;
}

.sync-screen {
  top:0;
  left:0;
  position: absolute;
  z-index: 120;
  height:100vh;
  width:100vw;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.582);
  display: flex;
  flex-direction: column;
}

.sync-screen-circular-progress {
  text-align: center;
  display: flex;
}

.circular-progress-small {
  width:30px !important;
  height:30px !important;
}

.sync-hidden {
  display: none;
}

.banner-card-display1 {
  max-width: 120px;
  opacity:0;
  top:30px;
  z-index: -1;
  position: absolute;
  rotate: 0deg;
  animation-name: scaleIn; 
  animation-duration: 1.5s;
  animation-delay: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.banner-card-display2 {
  max-width: 120px;
  opacity:0;
  top:30px;
  z-index: -1;
  position: absolute;
  rotate: 0deg;
  animation-name: scaleIn2; 
  animation-duration: 1.5s;
  animation-delay: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.banner-card-display3 {
  max-width: 120px;
  opacity:0;
  top:30px;
  z-index: -1;
  position: absolute;
  rotate: 0deg;
  animation-name: scaleIn3; 
  animation-duration: 1.5s;
  animation-delay: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;  
}

@keyframes scaleIn {
  from {
    opacity: 0;    
    right:0vw;
    rotate: 0deg;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    /* right: 52vw; */
    right: calc(50vw - 0px);
    rotate: -15deg;
    transform: rotate(-15deg);
  }
}


@keyframes scaleIn2 {
  from {
    opacity: 0;    
    right:0vw;
    rotate: 0deg;
    transform: rotate(0deg);
    
  }
  to {
    opacity: 1;
    right: calc(50vw - 80px);
    rotate: -15deg;
    transform: rotate(-15deg);
    
  }
}


@keyframes scaleIn3 {
  from {
    opacity: 0;    
    right:0vw;
    rotate: 0deg;
    transform: rotate(0deg);
    
  }
  to {
    opacity: 1;
    right: calc(50vw - 140px);
    rotate: -15deg;
    transform: rotate(-15deg);
    transform: scale(1);
  }
}



.banner-image {
  display: block;
  max-width: 100%;
  max-height: stretch;
  border-radius: 12px;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
}

.grow {cursor: pointer;  transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

.selector-setcode {

  font-size: 0.8em;
  color: rgb(106, 106, 106);
  text-transform: uppercase;
  min-width: 50px;
  margin-bottom: 0px;
}

.set-selector {
  display: block;
  position:sticky;
  top:100px;
  z-index:2;
  position: -webkit-sticky;

}

.set-selector-dropdown {
  /* padding-top: 3em;
  position: fixed; */
  /* width:100%;
  background-color: white;
  z-index: 100;
  top:110px;
  position:sticky; */

  /* height:84px; */
  width: 100%;
  background-color: white;
  z-index: 100;
  top: 100px;  

  position: sticky;
  max-width: 1117px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #88c6f9;
  border-right: 1px solid #88c6f9;
  border-bottom: 1px solid #88c6f9;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
}

.breadcrumb-link {
  font-size: 1.3em;  
  font-weight: 500;
  color:#2196f3;
  cursor: pointer;
}
.breadcrumb-link a {
  font-size: 1.3em;
  font-weight: 500;
  color:#2196f3;
}


.button-link:hover {
  color:white;
  background-color: #6db7f3;
}

.button-link {
  display: inline-block;
  background-color: #2196f3;
  padding:0.5em;
  color:white;
  margin:1em 0em 0em 0em;
  border-radius: 4px;  
}

.product-series-image {
  height:5em;
}

.product-set-image {
  height:7em;
}

.product-hover-button {
  /* background-color: white; */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 10;
}

.product-card-singles {
  width: 100%;
  overflow: hidden;
}


.product-card .product-hover-button {
  display: block;
  opacity: 0;
  /* animation: spin 1s forwards; */
  /*Set our animation play state to paused initially */
  /* animation-play-state: paused;  */
  z-index: 100;
  }

.product-card .product-hover-button-loading {
    display: block;  
    opacity: 100;  
    z-index: 100;
  }

  
.product-card:hover .product-hover-button {
  display: block;  
  opacity: 100;
  /* animation-play-state: running; */
}

@keyframes spin {
  100% {opacity: 100; }
}



.container-body {
  min-height:300px;
  flex-shrink:0;
  flex-grow:1;
  padding-top:0em;
  padding-bottom:6em;
  /* background-color:white;   */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2368bef4' fill-opacity='0.48' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");  */
}


.container-column-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

}

.container-column-flex-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: sticky;
  position: -webkit-sticky;
  top:95px;
  z-index: 10;
}


.container-column-flex-mobile-nonsticky {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  /* position: sticky; */
  /* position: -webkit-sticky; */
  /* top:95px; */
  /* z-index: 10; */
}


.container-row-flex {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}


.container-flex-space {
  flex:1;
}

.container-column-left  {
  display: inline-block;  
  width:48%;
  vertical-align: top;
}

.container-column-right  {
  display: inline-block;  
  width:48%;
  padding-left: 2em;
}

.container-column-left.mobile  {
  display: inline-block;  
  width:100%;
}

.container-column-right.mobile  {
  display: inline-block;  
  width:100%;
}

.product-details-description-column {
  min-width: 12em;
  display: inline-block;
}

.product-details-description-column.left {
  font-weight: bold;
}

.modal-button-view {
  margin:1em !important;
}

.footer-disclaimer a {
  color:white;  
}
.footer-disclaimer a:visited {
  color:white;  
}

.menu-button {
  position: sticky;
  position: -webkit-sticky;
  top: 110px;
  z-index: 10;
}

.modal-button {
  margin-bottom: 1em;
  max-width: 60em !important;
  min-width: 9em !important;
  /* margin-left:1em !important; */
  /* margin-right:1em !important; */
  display: inline-block;
  /* margin:1em !important; */
  width: 100%;
  height: 60px;  
  /* margin:1em !important; */
}

.modal-button-small {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  width:100px;
  min-width: 9em !important;
  margin:1em !important;
  height:50px;
}
.modal-button-small:first-child {
 margin-left: 0px !important;
}

.admin-order-buttons button{
  max-width: 24em !important;
  margin:1em;

}


.admin-buttons .modal-button {
  margin-bottom: 1em;
  background-color: #888;
  max-width: 60em !important;
  min-width: 9em !important;
  /* margin-left:1em !important; */
  /* margin-right:1em !important; */
  display: inline-block;
  /* margin:1em !important; */
  width: 5em;
  max-height: 3.1em;
  margin:.1em;  
  text-align: left;
  padding-top: 0;
  margin-top: 0;
  padding-left: 0.3em;  
  padding-right: 0.1em;  
  line-height: 1em;
  /* min-height: 3.5em; */
}

.order-list-button {
  margin:0.5em;
}
.order-summary-block {
  display: inline-block;
  vertical-align: top;
  padding-left:0em;
  padding-right:0em;
}

.order-summary-block.white {
  background-color: rgba(255, 255, 255, 0.434);
}

.order-btn-checkout {
  padding:4em;
}

.order-summary-item {
  cursor: pointer;
  display: inline-block;
  text-align: left;
  padding-right:1em;
  padding-left:1em;
  width: auto;
  /* min-width: 10em; */
  vertical-align: middle;
}

.order-summary-item.block {  
  display: block;
}


.cart-checkout-total-mobile {
  background-color: white;
  border-radius: 4px;
  margin:1em;  
  margin-bottom: 0em;
  border: 1px solid #88c6f9;
  font-size: 1.2em;
}

.cart-checkout-total-mobile-open {
  background-color: white;
  border-radius: 4px;
  margin:1em;  
  margin-bottom: 0em;
  border: 1px solid #88c6f9;
  font-size: 1.2em;
  border-bottom: 0px solid white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.mobile-total-message {
  font-size: 10px;
  color:gray;
}

.order-summary-mobile-details  {
  font-size:12px;
}

.order-summary-container {
  background-color: white;
  margin: 1em;
  margin-top: -3px;
  margin-bottom: 0em;
  padding-top: 0px;
  border: 1px solid #88c6f9;
  font-size: 1.2em;
  border-top: 0px solid white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

}

.column-50 {
  width:48% !important;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}


.compareColumnRow {
  width:30em;
  margin-top:1em;
  margin-bottom:1em;
}

.compareColumn {
  min-width: 5em;
  display: inline-block;
  text-align: right;
  padding-left: 2em;
}


.order-summary-item-auto {
  width: auto;
  min-width: auto;
}

.small-image {
  width:5em;
  height:auto;
}

a
{
  text-decoration: none;
  color: #404040;
}

  
p {
  font-family: "Roboto", sans-serif;
  font-weight:400;
  font-size:1em;
  padding: 0px;  
}

ul {
  padding: 5px;
  margin:0;
  color:white;
  font-family: "Helvetica Neue", "Roboto", sans-serif;
  font-weight: 300;
  font-size:1.1em;
}

li {
  list-style: none;
  text-align: left; 
  /* font-size: 12px; */
  color: #111;
}

h1 {
  padding-top: 10px;
  font-size: 1.9rem;
}

.disc{
  list-style: disc;
  text-align: left; 
  /* font-size: 12px; */
  color: #111;
}



.footer  h5 {
  padding-left: 5px;
}

.container {
 padding: 0px;
 max-width: 100%;
}

.thumbs-wrapper {
  padding-bottom: 0px;
}


.main-container {
 min-height: 100vh; /* will cover the 100% of viewport */
 /* overflow: hidden; */
 display: block;
 /* position: relative; */
 padding-bottom: 100px; /* height of your footer */
}


.disclaimer-popsockets {
  padding-top: 10px;
  text-align: center; 
  font-size: 0.8em; 
}


.disclaimer-copyright {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center; 
  font-size: 0.9em; 
  color:#ccc;
}


.grid-item-content {
  padding: 12px;
  font-size:1.2em;
  line-height: 1.7em; 
  display: inline-block; 
}

.grid-item-content li a {
  color:white;
}

.footer-item-center {
  text-align: center;
}

.footer-item-content {
  padding: 12px;
  padding-left:1em;
  padding-right:1em;
  font-size:1.2em;
  line-height: 1.7em; 
  display: inline-block; 

}

.footer-item-content li a {
  color:white;

}

.grid-item-list {
  padding-top:3em;
}


.carousel {
  background-color: transparent !important;
}

.carousel-initialized {
  overflow: visible !important;
}

.slide {
  background-color: transparent !important;
}

.carousel .thumb {
  border:3px solid black;
}

.carousel .thumb.selected {
  border:3px solid white;
}

#productCarouselParent .carousel .thumb {
  border:1px solid white;
  border-radius: 3px;
}

#productCarouselParent .carousel .thumb.selected {
  border:1px solid rgb(85, 85, 85);
  border-radius: 2px;
}

/* .carouselParent { */
  /* background-color: black; */
/* } */

.about-us p {
  padding-right: 70px;
}


.control-dots {
  margin:0px !important;
  padding:0px !important;
}

.paper-header {
  text-align:left;
  padding-bottom:1em;
}

.paper-single-centered {
  margin: 4%;
  padding:3em;
}

.paper-single-centered p{
  text-align: left;
}

.image-fadein-transition {
  width: 100%;
  opacity: 0;
}

.image-show {
  width: 100%;
  opacity: 1;
}


.image-fadein-transition-in {
    -webkit-transition: opacity 0.6s ease-in;
    -moz-transition: opacity 0.6s ease-in;
    -ms-transition: opacity 0.6s ease-in;
    -o-transition: opacity 0.6s ease-in;
    transition: opacity 0.6s ease-in;
     opacity: 1;  
}

.image-fadein-finished {
  width: 100%;
  padding-bottom: 150%;
  opacity: 1;  
}

.image-fadein {
  width: 100%;
  padding-bottom: 0%;
  opacity: 0;
  animation-name: fadeIn; 
  animation-duration: 0.9s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.thumbs-wrapper {
  margin:0px !important;
  padding-top:6px;
  padding-bottom:6px;
}
.amzn-native-container {
  margin-left: auto;
    margin-right: auto;
    background-color: white;
}

.flex-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}


.order-card {
  background-color: white;
  border:1px solid #ccc;
  width:100%;
  text-align: left;
  padding:1em;
}

.order-card-image {
  width:4em;
  height:auto;
  padding-right: 1em;
}
.order-card-total {
  font-size: 1em;
  padding-top:1em;
}

.order-card-left {
  width:50%;
  display: inline-block;
}

.order-card-right {
  width:50%;
  display: inline-block;
  vertical-align: top;
  /* text-align: right; */
}


.product-circle-loader {
  margin-left: auto;
  margin-right: auto;
  padding:10em;
  margin-top:20em;
}


.product-modal {
  display: inline-block;
  /* min-width: 40em; */
  vertical-align: top;
  padding:2em;
}

.set-card {
  background-color: white;
  padding:1em;
  margin:1em;
  border-radius: 3px;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  min-width: 400px;
}

.set-card-left{
  display: inline-block;
}

.set-card-right{
  display: inline-block;  
}

.product-card {
  width:14em;  
  margin:0.35em;
  margin-left:0em;
  margin-right:0em;
  /* border: 1px solid rgb(230, 230, 230); */
  padding:.5em;
  display: inline-block;
  vertical-align: top;
  background-color:white;
  /* box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242); */
  border-radius: 0.3em;

  opacity: 0;
  animation-name: fadeIn; 
  animation-duration: 0.9s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.product-card.single {
  width:10em;  
  box-shadow:none;
  border:none;
  border-radius: none;
}


/* .product-card-singles .product-card {
  width:10em;
  height:20em;  
} */

.product-card-object {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}


.product-card.mobile {
  margin-left:0em;
  margin-right:0em;
  width:13em;
  height: auto !important;
}

.product-card.mobile-single {
  margin-left:0em;
  margin-right:0em;
  width:10em !important;
  height:auto !important;
}

.product-card.mobile.single {
  margin-left:0em;
  margin-right:0em;
  padding-left: 0.5em;
  padding-right:0.5em;
  width:10em !important;
  height:auto !important;
}

.product-condition {
  padding-top:2em;
  font-size: 16px;
  font-weight: bold;
  color: rgb(136, 136, 136);
}

.product-card.desktop {
  margin-left:0em;
  margin-right:0em;
  height: auto !important;
  min-height: 355px;
}

.product-card.desktop.single {
  width:10em;
}

.product-quickview-image {    
  max-width:17em;
  /* max-height:17em; */
  width: 100%;
  height: auto;
  /* user-drag: none;  */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}


.product-quickview-image.thumb {    
  width: 100%;
}

.product-image {    
  max-width:12em;
  max-height:12em;
  width: auto;
  height: auto;
  /* user-drag: none;  */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.product-reviews-title {
  font-size: 1em;
}


.product-title {
  font-size:0.9em;
  padding: 0em;
  padding-top: 1em;
  padding-bottom: 0em;
  text-align: left;
  line-height: 1em;
  min-height: 4em;
  /* height: 5em; */
}

.product-title.card {
  height: 1em;
}

.product-title.card-single {
  height: 1em;
  margin-bottom: 0em !important;
  padding-top:0.5em;
  padding-bottom:2em;
  line-height: 0.9em;
  /* color:white; */
  font-weight: bold;
  /* background-color: #ccc; */
}


.product-description {
  font-size:1em;
  padding: 1em;  
  text-align: left;
}


.product-singles-set-header {
  padding:1em;
}

.product-singles-set-header p {

  margin-bottom:0.2em;
  color:#373737;
  font-weight: bold;

}

.product-price {
  font-size:1.5em;
  font-weight: bold;
  color:#2373b1;
  text-align: left;  
  margin-top:0.5em;
  margin-bottom:0.25em;
}
.product-price.details {
  font-size: 1.8em;
}

.price-strike {
  text-decoration: line-through;
  font-weight:300;
}

.card-detail-group p {
margin: 0;
padding:0
} 

.split-1-3 {
  display: inline-block;
  width:32%;
  vertical-align: top;
}

.card-detail-flag.bold{
  font-weight: bold;
  font-size: 1.8em;
}

svg {
  font   : bold 1em 'Nunito';
  width  : 100%;
  /* text-shadow: 4px 4px 3px rgb(0, 0, 0); */
  /* height : 1em; */
}

text {
  /* fill            : rgb(68, 192, 241); */
  /* stroke          : rgb(209, 57, 223); */
  stroke-width    : .04em;
  stroke-linejoin : round;
  padding:1em;
  margin:1em;
  /* animation       : 20s pulsate infinite; */
}

@keyframes pulsate {
  50%{ stroke-width:.02em }
}

.fun-title {
  font-family:'Nunito', 'Roboto', sans-serif;
  font-size:2.4em;
  color:rgb(68, 192, 241);
  font-weight: bold;
  text-shadow: rgb(209, 57, 223) 1px 1px;
  margin-bottom:0em;
}

.filter-title {
  font-family:'Nunito', 'Roboto', sans-serif;
  font-size:1.4em;
  color:rgb(68, 192, 241);
  font-weight: bold;
  text-shadow: rgb(209, 57, 223) 1px 1px;
  margin-bottom:0em;
}



.card-detail-flag {
  font-family: 'Nunito', 'Roboto', sans-serif;
  /* transform: skewX(-20deg); */
  /* background-color: black; */
  color:black;
  padding:0em !important;   
  margin:0em !important;
  border-radius: 8px;
  font-size: 1.5em;
  /* text-shadow: rgb(97, 97, 97) 1px 1px; */
  /* letter-spacing: 2px; */
  /* box-shadow: 0px 1px 0px grey; */
  /* border:1px solid gray; */
  /* font-weight: thin; */
}

.popover-msg {
  width: 20em;
  height:14em;
  padding:1.6em;
  text-align: left;

}

.tcgplayer-logo {
  width:10em;
}
.tcgplayer-msg {
  padding-top:1em;
}

.tcgplayer-group {
  padding-top:.2em;
  display: inline-block;
}

.tcgplayer-link {
  font-size: 1em;
  color:rgb(114, 143, 216);
  display: inline-block;

}
.icon-fire {
  background-image: url(/images/energy-types.png);
  background-position: 0px 0px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.icon-darkness {
  background-image: url(/images/energy-types.png);
  background-position: 0px 25px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.icon-fairy {
  background-image: url(/images/energy-types.png);
  background-position: 50px 75px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}


.icon-dragon {
  background-image: url(/images/energy-types.png);
  background-position: 0px 50px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}



.icon-water {
  background-image: url(/images/energy-types.png);
  background-position: -75px -50px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.icon-psychic {
  background-image: url(/images/energy-types.png);
  background-position: -75px -25px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.icon-fighting {
  background-image: url(/images/energy-types.png);
  background-position: -50px -25px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.icon-lightning  {
  background-image: url(/images/energy-types.png);
  background-position: 50px -50px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.icon-grass  {
  background-image: url(/images/energy-types.png);
  background-position: -25px -50px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}


.icon-metal {
  background-image: url(/images/energy-types.png);
  background-position: -75px 0px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}


.icon-colorless {
  background-image: url(/images/energy-types.png);
  background-position: -25px 0px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin:0.2em;
}

.card-detail-item-group {
  padding-top:2em;
  height: 3em;
}

.card-detail-value-large {
  /* float: right; */
  /* font-family: 'Nunito', 'Roboto', sans-serif; */
  /* font-size: 1.5em; */
  /* font-weight: normal !important; */
  vertical-align: top;
}

.card-detail-value {
  /* float: right; */
  font-weight: normal !important;
}
.card-detail-strong-value {
  font-size: 1.5em;
}

.card-detail-ability-name {
  font-size: 1.2em;
  font-weight: bold;
  padding-top:0em !important;
}

.card-detail-attack-cost {
  font-weight: bold;
  font-size: 1.2em;
  width: 33%;
  display: inline-block;
}

.card-detail-attack-name {
    font-weight: bold;
    font-size: 1.3em;
    width: 33%;
    display: inline-block;
    vertical-align: top;
}

.card-detail-attack-damage {
  font-weight: bold;
  font-size: 1.3em;
  width: 33%;
  display: inline-block;
  text-align: right;
  vertical-align: top;
}

.card-detail-text {
  color: #575353;  
}

.card-detail-flag span {
  transform: skewX(20deg);
  padding:1em;   

}


.card-detail-small-group {
  padding:1em;
}

.card-detail-group.top {
  padding-top: .3em !important;
}

.card-detail-group span {
  margin: 0;
  padding:0;
  /* font-weight: bold; */
  }

  .card-detail-item {
    vertical-align: top;
  }


.product-details {
  text-align:center;
  padding-bottom: 8em;
  display: flex;
}

.product-details-description {
  padding-top:1em;
  /* padding-left:1em; */
}

.product-badge {
  background-color: rgb(74, 74, 74);
  color: white;
  font-size: 0.9em;
  padding-left:0.4em;
  padding-right:0.4em;
  padding-top:0.3em;
  padding-bottom:0.3em;
  margin-right: 1em;
  
  width: auto;
  height: auto;
  font-size: 0.8125rem;
  margin-left:0em;
  border-radius: 3px;

}

.product-badge.low {
  background-color: rgb(233, 166, 22);
  margin-left:0em;
}

.product-badge.preorder {
  background-color: rgb(233, 166, 22);

}

.product-image-badge {
  background-color: rgb(74, 74, 74);
  color: white;
  font-size: 0.9em;
  padding-left:0.4em;
  padding-right:0.4em;
  padding-top:0.3em;
  padding-bottom:0.3em;
  margin-right: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  font-size: 0.8125rem;
  margin-left:0em;
  border-radius: 3px;
}

.product-image-badge.low {
  background-color: rgb(233, 166, 22);
}

.product-image-badge.preorder {
  background-color: rgb(233, 166, 22);
}


.product-tag-instock {
  background-color: rgb(38, 128, 51);
  color: white;
  font-size: 0.9em;
  padding:0.4em;
  margin-right: 1em;

}

.product-tag-soldout {
  background-color: rgb(74, 74, 74);
  color: white;
  font-size: 0.9em;
  padding:0.4em;
  margin-right: 1em;
}

.detail-image {
 /* display: inline-block; */
  width: 45%;
  position: sticky;
  top: 80px;
  float: left;
  /* margin-left: 100px; */
  padding-top: 40px;
  min-height: 420px;
  max-height: 20em;
  padding:4em;
  z-index: 1;
}

.detail-description {
  /* display: inline-block; */
  width: 50%;
  float: right;
  /* padding-left: 30px; */
  /* padding-right: 60px; */
  padding-top: 0px;
  max-width: 48em;
  /* min-width: 46em; */
  text-align: left;
  margin-right: 0px;
  margin-left: 0px;
 }

.detail-image-mobile {
  width: 320px;
  position: relative;
  top: 0px;
  float: none;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  min-height: 420px;
}



.detail-description-mobile {
  float: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  max-width: 700px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;  
}
.detail-description-mobile h2 {
  text-align: left;
}

 .detail-addtocart-btn {
   margin-left:auto;
   margin-right:auto;
   margin-top:4em;
   font-size:1.7em;
   background-color: black;
   color:white;
   width:10em;
   height:3em;
 }

 .cart-item {
   display: flex;
   padding-top:1em;
   padding-bottom:1em;
 }
 .cart-details {
  display:flex;
  text-align: center;
}
 

 .order-summary-details, .order-summary-amount {
  display:inline-block;
  /* padding-left:1em; */
  /* padding-right:1em; */
  vertical-align: middle;
  text-align: left;
}

.order-details {
  padding-top:2em;
}

.bold-item {
  font-weight:bold;
}

 .cart-wrapper {
   padding-top:0em;
 }


 .cart-header {
   display: inline-block;
   padding-left:1em;
   padding-right:1em;
   padding-top:1em;
   margin-top:0em;
   font-size:1.3em;
   text-align: center;
   margin-left:auto;
   margin-right:auto;
   
 }


 .close-header-icon {
  /* display:inline-block; */
  text-align:right;
  padding:0em;
  float:right;
  padding-right:.5em;
  padding-left: .5em;
  position: absolute;
  left:1em;
  top:1em;
 }


.filter-button {
  padding:0.5em;
  border:1px solid #2196f3;
  border-radius: 3px;
  margin:0.4em;
  margin-bottom: 1em;
  cursor: pointer;
} 

 .close-sub-icon {
  /* display:inline-block; */
  position: absolute;
  right:1em;
  top:1em;
 }


 .cart-subtotal {
  padding-left:0.65em;
  padding-right:0.65em;
  padding-top:.4em;
  padding-bottom:24px;
  margin-top:0em;
  margin-bottom:0em;
  font-size:1.2em;
  /* border-top:1px solid #ccc; */
  text-align: center;
  cursor: pointer;
 }

 .card-errors {
   margin-top:2em;
   margin-bottom:1em;
   color:#cd3d64 !important;
   padding-left:0.3em;
   padding-right:0.3em;
 }

 .cart-checkout {
   display: inline-block;
   margin-bottom:4em;
 }
 .cart-image {
   /* display: inline-block; */
   /* height:20px; */
   width:auto;   
   margin-right:0em;
   float: right;
   max-height: 80px;

 }

 .cart-image-order-complete {
  /* display: inline-block; */
  height:5em;
  width:auto;   
  margin-right:1em;
  float: left;
}
.dropdown-image-container {
  min-width: 40px;
}
.dropdown-image {
  width:auto;     
  margin-right:0em;
  max-height: 40px;
}
 .cart-image-parent {
  width: auto;
  min-width: 130px;
}

.card-search-details {
  display: inline-block;
  padding-left:2em;
  min-width: 10em;
  /* float: right; */
}
.card-search-result {
  display: block;
  border:1px solid #ccc;
  padding:1em;
  margin:1em;
  width: 100%;
  cursor: pointer;
}

.card-search-result.selected {
  display: block;
  border:1px solid rgb(223, 192, 57);
  padding:1em;
  margin:1em;
  width: 100%;
  cursor: pointer;
}

.popup-header:hover {
  /* text-decoration: underline; */
  color: #2196f3;
}

.popup-header {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.product-selection {
  padding-left: 2em;
  padding-top:1em;
}
.product-selection p {
  font-size: 1em;  
  color: #404040;
  line-height: 1em;
}

.campaign-subscriber-email {
  padding: 1em;
  margin:0.5em;
  width:50px;
  background-color: white;
  border:1px solid #ccc;
  color:#ccc;

}
.campaign-subscriber-email-error {
  padding: 1em;
  margin:0.5em;
  width:50px;
  background-color: rgb(252, 181, 159);
  border:1px solid rgb(206, 88, 38);
  color:rgb(94, 93, 93);
}

.campaign-subscriber-email-success {
  padding: 1em;
  margin:0.5em;
  width:50px;
  background-color: rgb(196, 252, 159);
  border:1px solid rgb(139, 206, 38);
  color:rgb(94, 93, 93);
}

.campaign-subscriber-email-current {
  font-weight: bold;
  padding: 1em;
  margin:0.5em;
  width:50px;
  background-color: white;
  border:1px solid black;

}

.subscriber-item {
  text-align: left;
  max-height: 300px;
  background-color: white;
  padding:1em;
  margin:0em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  border:1px solid #ccc;
  cursor: pointer;
}

.subscriber-item-sent {
  background-color: greenyellow;
  text-align: left;
  max-height: 300px;
  padding:1em;
  margin:0em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  border:1px solid #ccc;
  cursor: pointer;
}

.subscriber-item-selected {
  background-color: gold;
  text-align: left;
  max-height: 300px;
  padding:1em;
  margin:0em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  border:1px solid #ccc;
  cursor: pointer;
}

.subscriber-item-unsubscribed {
  background-color: rgb(245, 125, 98);
  text-align: left;
  max-height: 300px;
  padding:1em;
  margin:0em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  border:1px solid #ccc;
  cursor: pointer;
}


.email-draft-item {
  text-align: left;
  max-height: 300px;
  background-color: white;
  padding:1em;
  margin:1em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
  border:1px solid #ccc;
}

.email-draft-item-list-header {
  background-color: white;
  position: sticky;
position: -webkit-sticky;
top: 0;
min-height: 60px;
border-bottom: 1px solid #ccc;
z-index: 1;
}

.email-draft-item-group {
  display: inline-block;
  width: 30%;
  border-radius: 3px;
}

.email-draft-item-list {
  vertical-align: top;
  display: inline-block;
  /* width: 30%; */
  max-height: 400px;
  min-width: 375px;
  overflow: scroll;
  background-color: white;
  box-shadow: 3px 3px 3px 1px rgba(38, 48, 68, 0.384);
  margin: 1em;
}


.email-draft-item-email {
  display: inline-block;
  width: 60%;
}


.card-set-dropdown {
  margin:1em;
  margin-top:0em;
  padding-top:1em;
  text-align: left;
  z-index: 10;
  /* float:left; */
}


.MuiFormControlLabel-label:hover {
  /* white-space: nowrap; */
  color:#2196f3;
}

.card-search-parent {
  background-color: white;
  position: sticky;
  top: 0;
}

.card-search-image {
 width: 3em; 
  margin: 0;
  vertical-align: top;
}
.card-image {
  /* width: 3em;  */
  width: 100%;
   margin: 0;
 }
.card-set-logo {
  width:7em;
  height:auto;
}
.card-set-name {
  padding-left: 1em;
  font-size: 1.15em;
}


.card-select-text {
   cursor: pointer;
 }
 
.card-search-name {
  font-size: 1em;
  margin: 0;
}
.card-search-series {
  font-size: 1em;
  margin: 0;

}

.card-search-set {
  font-size: 1em;
  margin: 0;

}
.card-search-number {
  font-size: 1em;
  margin: 0;

}
.card-search-rarity {
  font-size: 1em;
  margin: 0;

}

 .cart-product {
  display: inline-block;
  vertical-align: top;  
 }


 .checkout-container {
   display: inline-block;
   width: 100%;
   padding-left:1em;
   padding-right:1em;
 }

 .checkout-container-steps-mobile { 
  display: inline-block;
  width:100%;
 }

 .checkout-container-steps {
  display: inline-block;
  width:60%;
  
}

.checkout-container-cart {
  display: inline-block;
  width:33%;
  position: sticky;
  top:8em;
}

 .checkout-step-label span {
   font-size: 1.3em !important;
   text-align: left;   
 }

 .checkout-step-label span.label-small {
  padding-left:2em;
  font-size: 0.4em !important;
  text-align: left;   
}

.checkout-step-label p.label-small {
  /* padding-left:1em; */
  margin-bottom: 0em;
  /* padding-top:1em; */
  font-size: 0.8em !important;
  text-align: left;   
}


.checkout-step-label div.label-small-button {
  padding:1em;
  padding-top:.5em;
  padding-bottom:.5em;
  font-size: 0.4em !important;
  text-align: left;   
  /* border:1px solid #ccc; */
  border-radius: 4px;
  width: 4em;
  cursor: pointer;
  margin-left:3em;
  float:right;
}

.checkout-form {
  border:1px solid #ccc;
  padding:2em;
  padding-top:.5em;
  margin-bottom:3em;
  /* min-width: 48em; */
  padding-left:0em;
  padding-right:0em;
  /* min-height: 30em; */
  max-width: 40em;
  margin-left:auto;
  margin-right:auto;
  background: linear-gradient(0deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 70%, rgba(240,240,240,1) 100%) !important;
  border-radius: 3px;
}

.checkout-form-group {
  padding:3em;
  max-width: 20em;
}

.checkout-place-order {
  margin:3em;
  margin-top:5em;
  margin-top:4em;
  width:10em;
  height:3em;
}

.checkout-complete-block {
  background-color: white;
  border: 1px solid #ccc;
  margin-bottom:4em;
  margin-left:1em;
  margin-right:1em;
}
.checkout-bold {
  font-style:bold;
  font-size:3em;
  padding-bottom:0em;
  margin-bottom:0em;
}


.checkout-complete {
  font-weight: 200;
  font-size: 2em;
  /* padding-top:2em; */
  padding-bottom:0em;
  margin-bottom:0em;
}

.checkout-complete-shipto {
  margin-top:2em;
  text-align: left;
  padding:3em;
  width:28%;
  font-weight: 200;
  font-size: 0.8em;
  display: inline-block;
}

.checkout-complete-span {
  font-weight: 500;
}


.checkout-complete-shipping {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-top:2em;
}

.checkout-complete-billing {
  width:50%;
  display:inline-block;
  margin-top:2em;
}

.checkout-complete-total-grp {
  display:inline-block;
  width:50%;
  margin-top:2em;  
}

@media screen and (max-width: 600px) {
  .checkout-complete-billing {
    width:100%;
  }
  .checkout-complete-total-grp {
    width:100%;
  }
  .checkout-complete-shipping {
    width:100%;
  }
}


.checkout-complete-items {
  margin-top:0em;
  text-align: left;
  padding:1em;
  padding-top:2em;
  height:69%;
  display: inline-block;  
}

.checkout-complete-items p {
  margin-bottom:0em;
}


.checkout-complete-subtotal {
  font-size: 1.1em;
  font-weight:400;
  /* float:right; */
  text-align: left;
}

.checkout-complete-tax {
  font-size: 1.1em;
  font-weight:400;
  /* float:right; */
  text-align: left;
  border-bottom:1px solid #ccc;
}


.checkout-complete-total {
  font-size: 1.1em;
  font-weight:500;
  /* float:right; */
  text-align: left;
}

.checkout-complete-total-span {
  float:right;
  padding-left: 1em;
}

.checkout-complete-shipto p {  
  margin:0;
}

.checkout-card-group {
  float:right;
}

.checkout-card-img {
  width: 4em;
  padding-left:.5em;
  padding-right:.5em;
}

.stripe-svg {
    width:8em;
    float:right;
    margin:0.5em;    
    margin-top:0em;    
}

.stripe-container {
  padding-left:0.6em;
  padding-right:0.6em;
  padding-top:5em;
}

.MuiStepper-root {
  padding-left:0em !important;
  padding-right:0em !important;
}

.MuiStepContent-last {
  margin:0em !important;
  padding:0em !important;
}

label {
  float:left;
}

.result-success {
  font-weight: bold;
  font-size: "2em";
  color:rgb(23, 106, 60);
}

.result-failure {
  font-weight: bold;
  font-size: "2em";
  color:rgb(184, 52, 28);
}

.label-number-tag {

  font-weight: bold;
  display: inline-block;
  background-color: rgba(204, 204, 204, 0.523);
  border-radius: 10px;
  padding:0.4em;
}

.label-large {
  font-size: 2em;
  display: inline-block;
  margin: 0.2em;
  text-align: left;
}

.address-card {
  width: 18em;
  margin:1em;
  /* padding:2em;  
  margin:2em;
  margin-bottom: 0em;
  padding-bottom:0em;
  margin-left:0em; */
  display: inline-block;
  vertical-align: bottom;
}

.address-card-edit {
  width: 100%;
  margin:1em;

  /* padding:2em;  
  margin:2em;
  margin-bottom: 0em;
  padding-bottom:0em;
  margin-left:0em; */
  display: inline-block;
  vertical-align: bottom;
}


.details-button {
  cursor: pointer;
}

.address-add-button {
  vertical-align: middle;
  display: inline-block;
  margin:2em;
}

.account-signout {
  margin-right:1em;
  margin-top:0em;
  float:right;
  max-width: 20em;
}

.account-tab {
  min-width: 4em;
  font-size: 1.2em;
  text-align: center;   
  display: inline-block;
  padding:1em;
  /* border: 1px solid rgb(227, 227, 227); */
  /* border-bottom:0px; */
  cursor: pointer;  
}

.account-tab-selected {
  min-width: 4em;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;  
  display: inline-block;
  padding:1em;
  /* border: 1px solid #ccc; */
  /* border-bottom:0px; */
  cursor: pointer;
  text-decoration: underline;
}


.create-account {
  /* border-top: 1px solid #e1e1e1; */
  border-radius: 4px;
  padding: 1em;
}
.create-account li {

  padding-left:1em;
}

.MuiListItem-root {
  color:black !important;
  font-size: 1rem;

}

.MuiMenuItem-root {
  color:black;
}
/* 
.MuiInputBase-root {
  color:white !important;
} */


.MuiInputBase-input p {
    margin-bottom: 0em !important;
    text-align: left;
    font-size: 1rem;
}

.shipping-address {
  font-size: 0.9em;
  text-align: left;
  margin-bottom: 0;
  /* padding-left:0.5em; */
  padding-top:0.5em;
}

.shipping-address p{
  margin-bottom: 0;
  font-size: 1em;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ReactModal__Overlay {

  z-index: 1200 !important;
}

.scroll-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}


.scroll-box__container {
  height: 100%;
  display: inline-flex;
}

.scroll-box__wrapper {
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: scroll;
  /* -webkit-overflow-scrolling: touch; */
  -ms-overflow-style: scroll; /* IE */
  overflow: -moz-scrollbars-none; /* Firefox */
  scrollbar-width: auto;
}

.scroll-box__wrapper::-webkit-scrollbar {
  width: 18px;

  /* display: none; */
  
  /* Chrome and Safari */
}

.scroll-box__wrapper::-webkit-scrollbar-thumb {
  background: #888;
}


::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(57,57,57, .6);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(156, 156, 156, .6);
}



.CalendarMonth_caption_gucugi {
  padding-bottom:47px !important;
}


/* CalendarDay__selected */
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}


.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 50px !important;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
}

.chart-price-label {
  font-size: 1.2em;  
  font-weight: bold;
}


.fee-estimate {
  padding-top:1em;
  font-size: 1.7em;
  font-weight: bold;
}
.product-price {
  font-size: 1.7em;
  font-weight: bold;

}

.result-title {
  padding-top:1em;
  font-size: 1.7em;
  font-weight: bold;
}

.fee-input-group {
  border-radius: 3px;
  border: 1px solid #ccc;
  padding:1em;
  margin-top:1em;
}

.product-group-container {
  background-color: rgba(255, 255, 255, 1);
  border:1px solid #d0eaff;
  border-radius: 4px;
  margin-top:3em;
  margin-bottom:3em;
  box-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);
}

.subscribe-big {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  padding-bottom: 100px;
  color: white;
}

.subscribe-normal {
  font-size: 1.6em;
  /* color: black; */
  /* text-shadow: 2px 2px 2px 1px rgba(135, 168, 230, 0.242);   */
  /* text-shadow: 2px 2px rgb(4, 4, 4); */
  background-color: rgba(255, 255, 255, 0.447);
  padding-left:1em;
  padding-right:1em;
}

.subscribe-dialog {
  background-color:white;
  background-image: url(/images/SubscribeImage.png);
  background-repeat:no-repeat;
  background-size: 100% auto;
  padding: 2em;
  margin:0;
  text-align: center;
  padding-top: 160px;
  min-width: 280px;
  min-height: 400px;
  max-width: 400px;
}

.subscribe-disclaimer {
  padding-top: 2em;
  font-size: 0.9em;
  color: #807f7f;

}

#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}


.recaptcha-form {
  display: inline-block;
}

.recaptcha-form-container {
  text-align: center;
  padding-top: 40px;
}